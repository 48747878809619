var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v(_vm._s(_vm.item.name)),
        ]),
        _c("span", { staticClass: "mr-2" }, [
          _vm._v(" | " + _vm._s(_vm.item.phone)),
        ]),
        _vm.item.isDefault
          ? _c("v-chip", { attrs: { color: "error", "x-small": "" } }, [
              _vm._v("Mặc định"),
            ])
          : _vm._e(),
      ],
      1
    ),
    _c("div", [_vm._v(" " + _vm._s(_vm.item.emails.join(", ")) + " ")]),
    _c("div", [_vm._v(" " + _vm._s(_vm.item.address) + " ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }