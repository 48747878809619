var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "xl" } },
    [
      _c(
        "v-card-text",
        [
          _c("p", { staticClass: "text-left text-h6 font-weight-regular" }, [
            _c(
              "span",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-icon",
                  { attrs: { color: "primary", large: "", left: "" } },
                  [_vm._v("mdi-information-outline")]
                ),
                _c("span", { staticClass: "primary--text" }, [
                  _vm._v("THÔNG TIN LIÊN HỆ"),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "pr-2",
                    attrs: {
                      "hide-details": "",
                      label: "Nhập họ tên",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      "prepend-inner-icon": "mdi-account",
                    },
                    model: {
                      value: _vm.senderName,
                      callback: function ($$v) {
                        _vm.senderName = $$v
                      },
                      expression: "senderName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "pl-2",
                    attrs: {
                      "hide-details": "",
                      label: "Nhập địa chỉ",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      "prepend-inner-icon": "mdi-map-marker",
                    },
                    model: {
                      value: _vm.senderAddress,
                      callback: function ($$v) {
                        _vm.senderAddress = $$v
                      },
                      expression: "senderAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pt-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "pr-2",
                    attrs: {
                      "hide-details": "",
                      label: "Nhập email",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      "prepend-inner-icon": "mdi-email",
                    },
                    model: {
                      value: _vm.senderEmail,
                      callback: function ($$v) {
                        _vm.senderEmail = $$v
                      },
                      expression: "senderEmail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "pl-2",
                    attrs: {
                      "hide-details": "",
                      label: "Nhập số điện thoại",
                      outlined: "",
                      rounded: "",
                      dense: "",
                      "prepend-inner-icon": "mdi-cellphone",
                    },
                    model: {
                      value: _vm.senderPhone,
                      callback: function ($$v) {
                        _vm.senderPhone = $$v
                      },
                      expression: "senderPhone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }