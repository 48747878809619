<template>
  <v-card rounded="xl">
    <v-card-text>
      <p class="text-left text-h6 font-weight-regular">
        <span class="d-flex align-center">
          <v-icon
            color="primary"
            large
            left
            >mdi-information-outline</v-icon
          >
          <span class="primary--text">THÔNG TIN LIÊN HỆ</span>
        </span>
      </p>
      <v-row no-gutters>
        <v-col cols="6">
          <v-text-field
            v-model="senderName"
            class="pr-2"
            hide-details
            label="Nhập họ tên"
            outlined
            rounded
            dense
            prepend-inner-icon="mdi-account"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="senderAddress"
            class="pl-2"
            hide-details
            label="Nhập địa chỉ"
            outlined
            rounded
            dense
            prepend-inner-icon="mdi-map-marker"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="pt-2"
      >
        <v-col cols="6">
          <v-text-field
            v-model="senderEmail"
            class="pr-2"
            hide-details
            label="Nhập email"
            outlined
            rounded
            dense
            prepend-inner-icon="mdi-email"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="senderPhone"
            class="pl-2"
            hide-details
            label="Nhập số điện thoại"
            outlined
            rounded
            dense
            prepend-inner-icon="mdi-cellphone"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import jwt_decode from 'jwt-decode';

export default {
  data: () => ({}),
  computed: {
    ...mapState('vsl/quotation', ['requestQuotation']),
    senderName: {
      get() {
        return this.requestQuotation.senderName;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({ senderName: value });
      },
    },
    senderAddress: {
      get() {
        return this.requestQuotation.senderAddress;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({ senderAddress: value });
      },
    },
    senderEmail: {
      get() {
        return this.requestQuotation.senderEmail;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({ senderEmail: value });
      },
    },
    senderPhone: {
      get() {
        return this.requestQuotation.senderPhone;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({ senderPhone: value });
      },
    },

    receiveName: {
      get() {
        return this.requestQuotation.receiveName;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({ receiveName: value });
      },
    },
  },
  watch: {},
  async created() {
    const token = localStorage.getItem('accessToken');
    const decoded = jwt_decode(token);
    const { name, phone, email, address } = decoded;
    if (name) {
      this.senderName = name;
    }
    if (phone) {
      this.senderPhone = phone;
    }
    if (email) {
      this.senderEmail = email;
    }
    if (address) {
      this.senderAddress = address;
    }

    console.log('[LOG] : decoded', decoded);
  },
  methods: {
    ...mapMutations('vsl/quotation', ['SET_REQUEST_QUOTATION']),
  },
};
</script>

<style></style>
