<template>
  <v-card rounded="xl">
    <v-card-text>
      <p class="text-left text-h6 font-weight-regular">
        <span class="d-flex align-center">
          <v-icon
            color="primary"
            large
            left
            >mdi-layers-triple-outline</v-icon
          >
          <span class="primary--text text-uppercase">{{
            $t('title-term')
          }}</span>
        </span>
      </p>
      <v-row no-gutters>
        <v-col cols="6">
          <v-autocomplete
            class="pr-2 primary--text"
            v-model="polTermCode"
            :items="termList"
            item-value="code"
            item-text="name"
            return-object
            hide-details
            dense
            outlined
            rounded
            :label="$t('label-pol-term')"
            prepend-inner-icon="mdi-robot-mower"
          />
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            class="pl-2"
            v-model="podTermCode"
            :items="termList"
            item-value="code"
            item-text="name"
            return-object
            hide-details
            dense
            outlined
            rounded
            :label="$t('label-pod-term')"
            prepend-inner-icon="mdi-robot-mower"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
  data: () => ({}),
  computed: {
    ...mapState('vsl/term', ['termList']),
    ...mapState('vsl/quotation', ['requestQuotation']),
    podTermCode: {
      get() {
        return this.requestQuotation.podTermCode;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({
          podTermCode: value?.code,
        });
      },
    },
    polTermCode: {
      get() {
        return this.requestQuotation.polTermCode;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({
          polTermCode: value?.code,
        });
      },
    },
  },
  watch: {
    podTermCode() {
      this.SET_REQUEST_QUOTATION({ commodities: [] });
    },
    polTermCode() {
      this.SET_REQUEST_QUOTATION({ commodities: [] });
    },
  },
  async created() {
    await this.fetchTermList();
  },
  methods: {
    ...mapMutations('vsl/quotation', ['SET_REQUEST_QUOTATION']),
    ...mapActions('vsl/term', ['fetchTermList']),
  },
};
</script>

<style></style>
