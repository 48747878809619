<template>
  <v-card>
    <v-card-title>
      <v-row
        no-gutters
        align="center"
      >
        <div>
          {{ $t('title-contact-select') }}
        </div>
        <v-spacer />
        <v-btn
          color="error"
          @click="$emit('close-dialog')"
        >
          <v-icon left>mdi-close</v-icon>
          {{ $t('button-close') }}</v-btn
        >
      </v-row>
    </v-card-title>
    <v-card-text class="mx-0 px-0">
      <div
        v-if="!isLoading"
        class="py-4"
      >
        <template v-for="item of contactList">
          <v-hover
            v-slot="{ hover }"
            :key="item.id"
          >
            <v-card
              style="cursor: pointer"
              class="mb-1"
              :color="hover ? 'secondary' : 'background'"
              :elevation="hover ? 1 : 0"
              :ripple="true"
              @click="onSelectContactItem(item)"
            >
              <QuotationFormSelectContactListItem
                class="pa-4"
                :item="item"
              />
            </v-card>
          </v-hover>
        </template>
        <v-btn
          color="primary"
          text
          width="100%"
          @click="onAddNewContact"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t('button-contact-add') }}</v-btn
        >
      </div>
      <div
        v-if="isLoading"
        class="my-4"
      >
        <v-card
          v-for="item in 5"
          :key="item.id"
          class="pa-4 mb-2"
          color="background"
        >
          <v-skeleton-loader
            type="text@3"
            :boilerplate="false"
            class="mb-1"
          />
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import QuotationFormSelectContactListItem from './QuotationFormSelectContactListItem.vue';
export default {
  components: {
    QuotationFormSelectContactListItem,
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState('userContact', ['contactList']),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchContactList();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapMutations('ui', ['TOGGLE_DIALOG_USER_SETTINGS']),
    ...mapActions('userContact', ['fetchContactList']),
    onSelectContactItem(item) {
      this.$emit('change', item);
      this.$emit('close-dialog');
    },
    onAddNewContact() {
      this.TOGGLE_DIALOG_USER_SETTINGS({
        status: true,
        tabKey: 'contacts',
      });
      this.$emit('close-dialog');
    },
  },
};
</script>

<style></style>
