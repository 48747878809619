var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("p", { staticClass: "display-1 mt-2 mb-5 text-uppercase" }, [
        _vm._v(" " + _vm._s(_vm.$t("title-request-a-quote")) + " "),
      ]),
      !_vm.isLoading
        ? _c("VesselScheduleSearchResultListItemV2", {
            staticClass: "mb-2",
            attrs: { schedules: _vm.vesselScheduleDetail, "hide-booking": "" },
          })
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "mt-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pr-4",
              attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 6 },
            },
            [
              _c("QuotationFormSelectContact", {
                staticStyle: { height: "100%" },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.mobile ? 12 : 6 } },
            [
              _c("QuotationFormSenderNote", {
                staticStyle: { height: "100%" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("QuotationFormTerm", { staticClass: "mt-4" }),
      _c("QuotationFormCommodity", { staticClass: "mt-4" }),
      _c("v-row", { attrs: { "no-gutters": "" } }, [
        _c(
          "p",
          {
            staticClass: "text-secondary pa-2",
            staticStyle: { "font-size": "12px", "font-style": "italic" },
          },
          [_vm._v(" " + _vm._s(_vm.$t("text-quotation-send-warn")) + " ")]
        ),
      ]),
      _c(
        "v-row",
        { staticClass: "mt-1 mb-10", attrs: { "no-gutters": "" } },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                disabled: !_vm.isFormValid || _vm.isLoading,
                loading: _vm.isLoading,
                color: "primary",
                large: "",
              },
              on: { click: _vm.onSubmit },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-send")]),
              _vm._v(" Gửi yêu cầu báo giá "),
            ],
            1
          ),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }