var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c("div", [
                _vm._v(" " + _vm._s(_vm.$t("title-contact-select")) + " "),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "error" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close-dialog")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-close")]),
                  _vm._v(" " + _vm._s(_vm.$t("button-close"))),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-text", { staticClass: "mx-0 px-0" }, [
        !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "py-4" },
              [
                _vm._l(_vm.contactList, function (item) {
                  return [
                    _c("v-hover", {
                      key: item.id,
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ hover }) {
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "mb-1",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      color: hover ? "secondary" : "background",
                                      elevation: hover ? 1 : 0,
                                      ripple: true,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSelectContactItem(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("QuotationFormSelectContactListItem", {
                                      staticClass: "pa-4",
                                      attrs: { item: item },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                }),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", text: "", width: "100%" },
                    on: { click: _vm.onAddNewContact },
                  },
                  [
                    _c("v-icon", [_vm._v("mdi-plus")]),
                    _vm._v(" " + _vm._s(_vm.$t("button-contact-add"))),
                  ],
                  1
                ),
              ],
              2
            )
          : _vm._e(),
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "my-4" },
              _vm._l(5, function (item) {
                return _c(
                  "v-card",
                  {
                    key: item.id,
                    staticClass: "pa-4 mb-2",
                    attrs: { color: "background" },
                  },
                  [
                    _c("v-skeleton-loader", {
                      staticClass: "mb-1",
                      attrs: { type: "text@3", boilerplate: false },
                    }),
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }