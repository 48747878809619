var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "xl" } },
    [
      _c(
        "v-card-text",
        [
          _c("p", { staticClass: "text-left text-h6 font-weight-regular" }, [
            _c(
              "span",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-icon",
                  { attrs: { color: "primary", large: "", left: "" } },
                  [_vm._v("mdi-layers-triple-outline")]
                ),
                _c("span", { staticClass: "primary--text text-uppercase" }, [
                  _vm._v(_vm._s(_vm.$t("title-term"))),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-autocomplete", {
                    staticClass: "pr-2 primary--text",
                    attrs: {
                      items: _vm.termList,
                      "item-value": "code",
                      "item-text": "name",
                      "return-object": "",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      rounded: "",
                      label: _vm.$t("label-pol-term"),
                      "prepend-inner-icon": "mdi-robot-mower",
                    },
                    model: {
                      value: _vm.polTermCode,
                      callback: function ($$v) {
                        _vm.polTermCode = $$v
                      },
                      expression: "polTermCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-autocomplete", {
                    staticClass: "pl-2",
                    attrs: {
                      items: _vm.termList,
                      "item-value": "code",
                      "item-text": "name",
                      "return-object": "",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      rounded: "",
                      label: _vm.$t("label-pod-term"),
                      "prepend-inner-icon": "mdi-robot-mower",
                    },
                    model: {
                      value: _vm.podTermCode,
                      callback: function ($$v) {
                        _vm.podTermCode = $$v
                      },
                      expression: "podTermCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }