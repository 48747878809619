var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "xl" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "p",
                { staticClass: "text-left text-h6 font-weight-regular" },
                [
                  _c(
                    "span",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "primary", large: "", left: "" } },
                        [_vm._v("mdi-package-variant")]
                      ),
                      _c(
                        "span",
                        { staticClass: "primary--text text-uppercase" },
                        [_vm._v(_vm._s(_vm.$t("title-commodity-info")))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialog.quotationFormCommodityCreate = true
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                  _vm._v(" " + _vm._s(_vm.$t("button-add-commodity")) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c("QuotationFormCommodityList"),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticStyle: { "max-width": "100vw" },
          attrs: {
            "max-width": "750px",
            fullscreen: _vm.$vuetify.breakpoint.mobile,
          },
          model: {
            value: _vm.dialog.quotationFormCommodityCreate,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "quotationFormCommodityCreate", $$v)
            },
            expression: "dialog.quotationFormCommodityCreate",
          },
        },
        [
          _vm.dialog.quotationFormCommodityCreate
            ? _c("QuotationFormCommodityCreate", {
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.quotationFormCommodityCreate = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }