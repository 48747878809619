var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mt-2",
      attrs: { rounded: "xl", ripple: false, to: _vm.to },
    },
    [
      _c(
        "v-card-text",
        { staticStyle: { "font-size": "12px" } },
        [
          _c(
            "v-row",
            { staticClass: "align-center", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "font-weight-bold primary--text text-truncate",
                  staticStyle: { "font-size": "11px" },
                  attrs: { cols: "2" },
                },
                [_vm._v(" " + _vm._s(_vm.item.vesselName) + " ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "font-weight-bold primary--text text-truncate",
                  staticStyle: { "font-size": "11px" },
                  attrs: { cols: "1" },
                },
                [_vm._v(" " + _vm._s(_vm.item.voyageCode) + " ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center text-truncate",
                  staticStyle: { "font-size": "11px", "font-weight": "600" },
                  attrs: { cols: "2" },
                },
                [
                  _c("div", { staticClass: "text-center text-truncate" }, [
                    _vm._v(" " + _vm._s(_vm.item.polTerminalName) + " "),
                  ]),
                  _c("div", { staticClass: "text-center text-truncate" }, [
                    _vm._v(" " + _vm._s(_vm.item.polName) + " "),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center text-truncate",
                  staticStyle: { "font-size": "11px", "font-weight": "600" },
                  attrs: { cols: "3" },
                },
                [
                  _c("div", { staticClass: "text-truncate" }, [
                    _vm._v(" " + _vm._s(_vm.item.podTerminalName) + " "),
                  ]),
                  _c("div", { staticClass: "text-truncate" }, [
                    _vm._v(" " + _vm._s(_vm.item.podName) + " "),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center text-truncate",
                  attrs: { cols: "2" },
                },
                [
                  _c("div", [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.formatDate(_vm.item.etd))),
                    ]),
                    _vm._v(" - "),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.formatDate(_vm.item.eta))),
                    ]),
                  ]),
                  _c("div"),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-center text-truncate",
                  attrs: { cols: "2" },
                },
                [
                  _vm.item.vesselSlot
                    ? _c(
                        "v-chip",
                        { attrs: { color: "success", outlined: "" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatVesselSlot(_vm.item.vesselSlot)
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.item.vesselSlot
                    ? _c(
                        "v-chip",
                        { attrs: { color: "error", outlined: "" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatVesselSlot(_vm.item.vesselSlot)
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }