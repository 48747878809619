var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "xl" } },
    [
      _c(
        "v-card-text",
        [
          _c("p", { staticClass: "text-left text-h6 font-weight-regular" }, [
            _c(
              "span",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "v-icon",
                  { attrs: { color: "primary", large: "", left: "" } },
                  [_vm._v("mdi-file-outline")]
                ),
                _c("span", { staticClass: "primary--text" }, [
                  _vm._v(_vm._s(_vm.$t("title-note"))),
                ]),
              ],
              1
            ),
          ]),
          _c("v-textarea", {
            attrs: {
              label: _vm.$t("label-note"),
              outlined: "",
              dense: "",
              rows: "3",
              "persistent-placeholder": "",
              "hide-details": "",
            },
            model: {
              value: _vm.senderNote,
              callback: function ($$v) {
                _vm.senderNote = $$v
              },
              expression: "senderNote",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }